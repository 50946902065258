import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import { Helmet } from "react-helmet"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import GlobalStyle from "../components/GlobalStyle"
import { CvAd } from "../components/ads/cvAd"
import { CityCategories } from "../components/cityCategories/CityCategories"
import DatePickerComponent from "../components/datePickerComponent/DatePickerComponent"
import MenuBar from "../components/layout/MenuBar"
import { Breadcrumbs } from "../components/layout/breadcrumbs/Breadcrumbs"
import { device } from "../utils/mediaQueries"



const Box = styled.div`
  margin: 50px;
  border-radius: 15px;
  padding: 0px;
  text-align: left;
  @media ${device.mobileL} {
    margin: 5px;
  }
`

const OtherCitiesContainer = styled.div`
  margin: 4rem;
  padding: 4rem;
  @media ${device.mobileL} {
    margin: 0.5rem;
    padding: 0.5rem;
  }
`

const HeaderContainer = styled.div`
  padding: 0rem 1rem;
`

const Background = styled(BackgroundImage)`
  margin-bottom: 30px;
  padding-bottom: 30px;
`

const Header1 = styled.h1`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  padding: 5px 20px;
  text-transform: capitalize;

  color: white;
  @media ${device.mobileL} {
    padding: 0.1rem;
    font-size: 35px;
  }
`
const Header2 = styled.h2`
  font-size: 27px;
  text-align: center;
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 5px 20px;
  color: white;
`

const LeftHeader2 = styled.h2`
  font-size: 27px;
  text-align: left;
  font-weight: 400;
  padding: 5px 0px;
  color: black;
`

const Header3 = styled.h3`
  font-size: 20px;
  text-align: center;
  font-weight: 200;

  padding: 5px 20px;
  color: black;
`

const ResultContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  padding: 50px 150px;
  grid-gap: 15px;
  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 2px;
  }
`

const TextContainer = styled.div`
  padding: 50px 150px;
  @media ${device.mobileL} {
    padding: 15px;
  }
`

const Template = ({ data, pageContext }) => {
  const { cityData, img, region } = pageContext
  const {
    city,
    hotelsCount,
    lomarengasId,
    parentCity,
    text,
    bookingId,
  } = cityData
  const imageData = data.file.childImageSharp.fluid
  const cities = data.cities.edges.filter(({ node }) => node.city !== city)
  const cityParts = data.cityParts.edges

  return (
    <GlobalStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`Varaa paras majoitus ${city[0].toUpperCase()}${city.slice(
            1
          )} - Top ${hotelsCount} mökit ja hotellit.`}
        </title>
        <meta
          name="description"
          content={`Vertaile täällä edullisin ja paras majoitus kohteessa ${city[0].toUpperCase()}${city.slice(
            1
          )}. Löydät vertailusta mökit, hotellit, hostellit, huoneistohotellit ja paljon muuta. Katso täältä.`}
        />
        <html lang="fi" />
      </Helmet>
      <Background Tag="section" fluid={imageData} backgroundColor={`#040e18`}>
        <MenuBar items={data.menuItems.edges} />
        <HeaderContainer>
          <Box>
            <Header1>Parhaat vuokramökit ja majoitus {city}</Header1>
            <Header2>Etsi sopiva majoitus alapuolelta</Header2>
            <DatePickerComponent
              urlFix={lomarengasId} // Tähän oikea id
              filter={city}
              name={city}
              bookingId={cityData.bookingId}
              isRegion={false}
            />
          </Box>
          <div></div>
        </HeaderContainer>
      </Background>
      <CvAd />
      <Breadcrumbs />
      {cityParts.length > 0 && !parentCity && (
        <>
          <Header3>Löydät muunmuassa nämä paikat majoittumiseen</Header3>
          <CityCategories cities={cityParts} bulk={true} />
        </>
      )}
      <TextContainer>
        <LeftHeader2>
          Tutustu paremmin kohteeseen {city[0].toUpperCase()}
          {city.slice(1)}
        </LeftHeader2>
        <ReactMarkdown>{text}</ReactMarkdown>
      </TextContainer>
      <Header3>Voit myös varata mökin seuraavista kohteissa</Header3>
      <OtherCitiesContainer>
        <CityCategories cities={cities} bulk={true} />
      </OtherCitiesContainer>
    </GlobalStyle>
  )
}
export const query = graphql`
  query($img: String!, $region: String!, $city: String!) {
    file(base: { eq: $img }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    menuItems: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "region" } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            name
          }
        }
      }
    }
    cities: allMongodbMokkivuokrausCities(filter: { region: { eq: $region } }) {
      edges {
        node {
          city
          parentCity
          region
        }
      }
    }
    cityParts: allMongodbMokkivuokrausCities(
      filter: { parentCity: { eq: $city } }
    ) {
      edges {
        node {
          city
          parentCity
          region
        }
      }
    }
    # allMarkdownRemark(
    #   filter: { frontmatter: { type: { eq: "city" } } }
    #   sort: { fields: frontmatter___name }
    # ) {
    #   edges {
    #     node {
    #       frontmatter {
    #         filter
    #         path
    #         name
    #       }
    #     }
    #   }
    # }
  }
`
export default Template
